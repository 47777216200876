const React = require("react");
const Providers = require("./providers/providers").default;

exports.wrapPageElement = ({ element, props }, themeOptions) => {
  return (
    <Providers {...props} marketing={themeOptions.marketing}>
      {element}
    </Providers>
  );
};

exports.onRouteUpdate = ({ location }) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "virtual_pageview",

    page_path: location.pathname + location.search,

    page_location: window.location.href,

    page_title: document.title,

    referrer: document.referrer || "",
  });
};
